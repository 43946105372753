import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import loginReducer from '../reducer/LoaderReducer.jsx'
import teacherTabReducer from '../reducer/TeacherTabReducer'

const allReducer = combineReducers({
  loginReducer: loginReducer,
  teacherTabReducer: teacherTabReducer,
  form: formReducer,
})

export default allReducer
