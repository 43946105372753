import Constant from '../../Utility/constant'

const LoaderReducer = (state = false, action) => {
  switch (action.type) {
    case Constant.reducerActions.LOADER:
      return action.payload
    default:
      return state
  }
}

export default LoaderReducer
