import Constant from '../../Utility/constant'

const TeacherTabReducer = (state = true, action) => {
  switch (action.type) {
    case Constant.reducerActions.TEACHER_TAB:
      return action.payload
    default:
      return state
  }
}

export default TeacherTabReducer
