import React from 'react'
import { Spinner } from 'reactstrap'
import { useSelector } from 'react-redux'

const Loader = () => {
  const loader = useSelector((state) => state.loginReducer)
  return (
    <div className="wone-loader">
      {loader && (
        <Spinner animation="border" variant="primary" color="primary" />
      )}
    </div>
  )
}
export default Loader
