import React from 'react'
import { usePastDelay } from 'react-lazy-no-flicker'
function FallbackLoading() {
  const past_delay = usePastDelay()
  if (!past_delay) return null
  return (
    <div>
      {' '}
      <center>Please,Wait project is Loading ... </center>
    </div>
  )
}

export default FallbackLoading
